<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('ticket_list') }}
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="border search-form">
      <a-form :colon="false" :hideRequiredMark="true" :model="searchFormState" autocomplete="off" :scrollToFirstError="true" layout="inline">
        <div style="width: 100%;padding: 0 10px">
          <a-row style="width: 100%">
            <a-col :span="8">
              <a-form-item name="ticketTypeId" :label="$t('work_order_type')">
                <a-select v-model:value="searchFormState.ticketTypeId" :options="ticketTypeOptions">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item name="ticketStatus" :label="$t('work_order_status')">
                <a-select v-model:value="searchFormState.ticketStatus" :options="ticketStatusOptions">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item name="orderId" :label="$t('booking_id')">
                <a-input v-model:value="searchFormState.orderId" :placeholder="'Jidu ' + $t('booking_id')" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row style="width: 100%;margin-top: 20px;">
            <a-col :span="8">
            </a-col>
            <a-col :span="8">
            </a-col>
            <a-col :span="8">
              <a-form-item style="float: right">
                <a-button type="primary" :loading="loading" @click="onSearch">{{ $t('search') }}</a-button>
                <a-button style="margin-left: 15px" @click="onReset">{{ $t('reset') }}</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </div>

    <div class="border order-list" v-if="!loading">
      <div class="card-mode">
          <table>
            <thead>
              <tr>
                <th style="width: 15%">
                  {{ $t('work_order_type') }}
                </th>
                <th>
                  {{ $ta('work_order|content', 'p') }}
                </th>
                <th style="width: 15%">
                  {{ $ta('relevant|time', 'p') }}
                </th>
                <th style="width: 12%">
                  {{$t('work_order_status')}}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item,index) in dataPaging.Rows" v-bind:key="index">
                <tr>
                  <td rowspan="3">
                    {{ isZhCn?item.ticket_type_name_cn:item.ticket_type_name_en }}
                  </td>
                  <td>
                    <div class="label">{{ $t('booking_id') }}</div>
                    <router-link :to="{name:'booking_detail', params:{ OrderId: item.order_id }}">{{ item.order_id }}</router-link>
                  </td>
                  <td>
                    <div class="label">{{ $ta('create|time') }}</div>
                    {{ dayjs.unix(item.ticket_sender_time).format('YYYY-MM-DD HH:mm') }}
                  </td>
                  <td rowspan="3">
                    <a-space direction="vertical">
                      <span :class="'t_status' + item.ticket_status">{{ ticketStatusArr[item.ticket_status] }}</span>
                      <a @click="showTicketLog(item)">{{ $t('work_order_log') }}</a>
                    </a-space>
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">
                    <div class="label">{{ $t('content') }}</div>
                    {{ item.ticket_content }}
                  </td>
                  <td>
                    <div class="label">{{ $ta('expected_completion_time') }}</div>
                    {{ dayjs.unix(item.ticket_expected_end_time).format('YYYY-MM-DD HH:mm') }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">{{ $t('completion_time') }}</div>
                    {{ !isEmpty(item.ticket_end_time) ? dayjs.unix(item.ticket_end_time).format('YYYY-MM-DD HH:mm') : getEmptyValue(null) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
      </div>
      <div style="display: flex;justify-content: space-between;">
        <div></div>
        <div>
          <a-pagination v-model:current="pageIndex" :pageSize="pageSize" :total="dataPaging.Total" :showSizeChanger="false" :showQuickJumper="true" :hideOnSinglePage="true" :show-total="total => $t('total_items', {s: total})"/>
        </div>
      </div>
      <a-empty v-if="!loading && dataPaging.Rows.length <= 0" style="width:100%;padding: 50px 0px" />
    </div>
    <div class="border order-list" v-else>
      <a-spin :spinning="loading" size="large" style="width:100%;padding: 50px 0px">
      </a-spin>
    </div>
  </div>


  <a-modal v-model:visible="ticketLogVisible" :maskClosable="false" :title="$t('work_order_log')" :width="950" :footer="null" :bodyStyle="{padding:'10px'}" @cancel="closeTicketLog">
    <ticket-log :order_id="selectedTicket?.order_id" :ticket_id="selectedTicket?.ticket_id"></ticket-log>
  </a-modal>
</template>

<script>
import {getTypes, getTicketList} from "@/services/ticket";
import {getEmptyValue,isEmpty} from "@/utils/util";
import dayjs from "dayjs";
import {inject} from "vue";
import TicketLog from "@/views/ticket/TicketLog";

export default {
  name: "TicketList",
  components: {
    TicketLog,
  },
  data() {
    let _ticket_status = this.$tm('ticket_status')
    let ticketStatusOptions = [{ value: '', label: this.$t('all') }]
    for(let key in _ticket_status) {
      ticketStatusOptions.push({ value: parseInt(key), label: _ticket_status[key] })
    }

    let ticketStatusArr = this.$tm('ticket_status')

    return {
      isEmpty,
      getEmptyValue,
      selectedMenuKeys: inject('selectedMenuKeys'),
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      dayjs,
      ticketStatusArr,
      loading: false,
      dataPaging: {PageIndex: 1, PageTotal: 0, PageSize: 10, Total: 0, Rows: []},
      pageIndex: 1,
      pageSize: 10,
      ticketTypeOptions: [],
      ticketStatusOptions,
      searchFormState: {
        ticketTypeId: '',
        ticketStatus: '',
      },
      ticketLogVisible: false,
      selectedTicket: null,
    }
  },
  mounted() {
    this.getTicketTypes()
    this.selectedMenuKeys = ['ticket_list']
    this.search()
  },
  watch:{
    pageIndex: function (value, value1) {
      if(value === value1)
        return

      this.search()
    }
  },
  methods:{
    getTicketTypes(){
      getTypes().then(res => {
        this.ticketTypeLoading = false
        const resData = res.data
        if (resData.Success) {
          this.ticketTypeOptions = [{ value: '', label: this.$t('all') }]
          let _ticket_type_group = this.$tm('ticket_type_group')
          for(let key in _ticket_type_group) {
            let _types = resData.Data.filter(item => { return item.ticket_type_group === parseInt(key) })
            let _options = []
            _types.forEach(item =>{
              _options.push({ value: item.ticket_type_id, label: this.isZhCn?item.ticket_type_name_cn:item.ticket_type_name_en })
            })
            this.ticketTypeOptions.push({ options: _options, label: _ticket_type_group[key] })
          }
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onSearch(){
      this.pageIndex = 1
      this.search()
    },
    onReset(){
      this.searchFormState = {
        ticketTypeId: '',
        ticketStatus: '',
        orderId: '',
      }
    },
    search(){
      let params = {}
      Object.assign(params, this.searchFormState)
      params.PageIndex = this.pageIndex
      params.PageSize = this.pageSize

      this.loading = true
      getTicketList(params).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.Success) {
          this.dataPaging = resData.Data
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    showTicketLog(ticket){
      this.selectedTicket = ticket
      this.ticketLogVisible = true
    },
    closeTicketLog(){
      this.selectedTicket = null
    }
  }
}
</script>

<style scoped>
.search-form {
  padding: 15px;
  margin-top: 20px;
}
.search-form .ant-divider-horizontal {
  margin: 15px 0;
}
.order-list .ant-divider-horizontal {
  margin: 10px 0;
}
.order-list {
  padding: 20px 15px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.card-mode table {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 15px;
}
.card-mode th {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  background-color: #FAFAFA;
}
.card-mode th strong {
  font-size: 14px;
}
.card-mode th, .card-mode td {
  padding: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.card-mode td {
  font-size: 13px;
  text-align: left;
  vertical-align: top;
}
.card-mode .label {
  font-size: 13px;
  color: rgba(0,0,0,0.6)
}
</style>